* {
  box-sizing: border-box;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
}

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}

::backdrop {
  background: #fff
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTimelineItem-root:before {
  display: none;
}


*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #C4C4C4;
}

*::-webkit-scrollbar {
  width: 10px;
  border-radius: 4px;
}

*::-webkit-scrollbar-track {
  background: #f0f5fa;
  border-radius: 10px; 
}

*::-webkit-scrollbar-thumb {
  background-color: #BFCAD9; 
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color:  #8692A3;
  cursor: pointer;
}
*::-webkit-scrollbar-thumb:active {
  background-color:  #636F7F;
  cursor: pointer; 
}

/* The marquee animation */
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}